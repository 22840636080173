import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Task } from 'app/tasks/Task';
import { EventEmitter } from '@angular/core';
import { TodoState } from 'app/todos/todos';


@Component({
  selector: 'app-task-new-task-form',
  templateUrl: './task-new-task-form.component.html',
  styleUrls: ['./task-new-task-form.component.scss']
})
export class TaskNewTaskFormComponent {
  @Input() submitBtnText: string | undefined;
  @Output() save = new EventEmitter<Task>();
  errorMsg = '';

  // Task Form
  estimatedHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  estimatedMinuteChoices = [
    0, 15, 30, 45
  ]
  // Create時は、`completed, actualTime`のForm無し
  taskForm = this.formBuilder.group({
    title: '',
    workFlow: '',
    estimatedTimeHours: 0,
    estimatedTimeMinutes: 0,
  });

  constructor(private formBuilder: FormBuilder) { }

  onSubmit() {
    const formData = this.taskForm.value;

    // APIへ送るためのデータを作る
    const toApiEstimatedTime = `${formData['estimatedTimeHours']} H : ${formData['estimatedTimeMinutes']} M`
    const state: TodoState = 'inProgress' // Create時は、inProgressでつくる

    const toApiData = {
      title: formData['title'],
      workFlow: formData['workFlow'],
      completed: false, // Create時は、`false`で送る
      actualHours: '', // Create時は、空
      estimatedHours: toApiEstimatedTime,
      state
    }

    this.save.emit(toApiData);
  }
}
