import { Component, Input } from '@angular/core';
import { Task } from 'app/tasks/Task';

@Component({
  selector: 'app-todo-index-task-item',
  templateUrl: './todo-index-task-item.component.html',
  styleUrls: ['./todo-index-task-item.component.scss']
})
export class TodoIndexTaskItemComponent{
  @Input() task!: Task;
}
