import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'app/tasks/Task';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-todo-details-task-list',
  templateUrl: './todo-details-task-list.component.html',
  styleUrls: ['./todo-details-task-list.component.scss']
})
export class TodoDetailsTaskListComponent {
  // 親のTodoDetailComponentnでは、
  // APIから`todo`が送られてくるのを待たずに、undefinedで`tasks`プロパティを渡している。

  // データが送られてくる前の表示画面を、このコンポーネント内で調整できるようにするため.
  @Input() tasks: Observable<Task[]>;
  @Output() deleteTask = new EventEmitter<Task>();
  @Output() toggleTaskComplete = new EventEmitter<Task>();
}
