import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { MyApiService } from 'app/todos/my-api-service.service';
import { Todo } from 'app/todos/todos';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-todo-index',
  templateUrl: './todo-index.component.html',
})
export class TodoIndexComponent implements OnInit {
  constructor(
    private myApi: MyApiService,
    private auth: AuthService
  ) { }

  user$ = this.auth.auth.user
  user: firebase.default.User
  inProgressTodos$: Observable<Todo[]>
  archiveTodos$: Observable<Todo[]>
  deletedTodos$: Observable<Todo[]>
  errorMsg: string | undefined

  ngOnInit(): void {
    this.user$.subscribe(user => {
      if (!user) { return }

      this.user = user
      this.inProgressTodos$ = this.myApi.getInProgressTodos(user.uid)
      this.archiveTodos$ = this.myApi.getArchiveTodos(user.uid)
      this.deletedTodos$ = this.myApi.getDeletedTodos(user.uid)
    })
  }

  deleteTodo(todo: Todo) {
    const todoId = todo.document_id
    const title = todo.title

    this.myApi.deleteTodo(todoId)
      .then(
        (_) => {
          alert(`Deleted Todo! Title is ${title}`)
        },
        (e) => {
          alert(e.message)
        }
      )
  }

  toggleTodoComplete(todo: Todo) {
    /*
      `todo.completed`がTrueかFalseかによる処理分けをする
    */
    const completed = todo.completed
    if (completed == true) {
      this.myApi.incompleteTodo(todo.document_id)
    } else {
      this.myApi.completeTodo(todo.document_id)
        .catch(e => {
          alert(e.message)
        })
    }
  }

  // completeTodo(todo: Todo) {
  //   /*
  //   `todo_complete`APIへPUTリクエストを送る
  //   リクエストが成功した場合 `todo.completed`をTrueにする
  //   */
  //   this.myApi.completeTodo(todo.document_id).
  //     error => {
  //       this.errorMsg = error
  //       alert('All Task not completed')
  //     }
  //   )
  // }
  // incompleteTodo(todo: Todo) {
  //   /* 
  //   `todo_complete`APIへDELETEリクエストを送る
  //   リクエストが成功した場合 `todo.completed`をFalseにする
  //   */
  //   this.myApi.incompleteTodo(todo.document_id).then(
  //     (_) => {
  //       todo.completed = false
  //       console.log('Incompleted Todo!')
  //     },
  //     error => {
  //       this.errorMsg = error
  //     }
  //     // error => this.errorMsg = error
  //   )
  // }

}
