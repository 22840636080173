import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = this.formBuilder.group({
    'email': '',
    'password': ''
  });
  errorMsg: string | undefined

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  onSubmit() {
    const formData = this.loginForm.value

    const toApiData = {
      email: formData['email'],
      password: formData['password'],
    }

    // Firebase Login
    this.authService.login(toApiData)
      .catch(error => this.errorMsg = error.message);
  }

  loginGoogle() {
    this.authService.googleLogin()
      .catch(error => this.errorMsg = error.message);
  }

}
