import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Todo, TodoState } from 'app/todos/todos';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-todo-new-todo-form',
  templateUrl: './todo-new-todo-form.component.html',
  styleUrls: ['./todo-new-todo-form.component.scss']
})
export class TodoNewTodoFormComponent implements OnInit {
  @Output() save = new EventEmitter<Todo>()

  // Form valuesの初期値
  // Createの時は、`completed`のフォームはいらない
  todoForm: FormGroup;
  estimatedHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  estimatedMinuteChoices = [
    0, 15, 30, 45
  ]
  errorMsg = '';

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    const initStartTime = new Date()
    // Minutesは15分刻みに表示するので、
    // それに合わせて見栄えのために 分・秒を0にセット
    initStartTime.setMinutes(0, 0, 0)

    this.todoForm = this.formBuilder.group({
      title: ['', Validators.required],
      importance: '',
      estimatedTimeHours: 0,
      estimatedTimeMinutes: 0,
      startTime: initStartTime
    })
  }

  onSubmit() {
    const formData = this.todoForm.value

    console.log(this.todoForm.controls)
    if (this.todoForm.errors) { return }

    //// APIへ送るためのデータを作る ////
    // estimatedTime:  例 '3 H : 5 M'
    const toApiEstimatedTime = `${formData['estimatedTimeHours']} H : ${formData['estimatedTimeMinutes']} M`

    const toApiData = {
      title: formData['title'],
      importance: formData['importance'],
      completed: false, // Create時は、`completed`はfalse
      start_time: formData['startTime'],
      duration: toApiEstimatedTime
    }

    this.save.emit(toApiData)
  }
}