import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  errorMsg: string | undefined;
  currentUser = this.authService.auth.user

  constructor(public authService: AuthService, private router: Router) {
  }
  
  logout() {
    this.authService.logout()
      .catch(error => this.errorMsg = error)
  }
}
