// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  firebase: {
    projectId: 'ios-todo-swift',
    appId: '1:852941523503:web:4175746072aa741fe354da',
    databaseURL: 'https://ios-todo-swift.firebaseio.com',
    storageBucket: 'ios-todo-swift.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCJOlPerUKxZg9F9WEhv8mCnv1KNYy5LEY',
    authDomain: 'ios-todo-swift.firebaseapp.com',
    messagingSenderId: '852941523503',
    measurementId: 'G-T1D1GW2WWN',
  },
  production: false
};
