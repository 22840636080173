import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { TodoDetailsComponent } from './todos/components/todo-details/todo-details.component';
import { TodoNewComponent } from './todos/components/todo-new/todo-new.component';
import { TodoEditComponent } from './todos/components/todo-edit/todo-edit.component';
import { TodoIndexComponent } from './todos/components/todo-index/todo-index.component';
import { TaskNewComponent } from './tasks/components/task-new/task-new.component';
import { TaskEditComponent } from './tasks/components/task-edit/task-edit.component';
import { LoginComponent } from './auth/components/login/login.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';
import { ProfileComponent } from './user-profile/user-profile.component';
import { UserEditComponent } from './users/components/user-edit/user-edit.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToIndex = () => redirectLoggedInTo(['']);

// ログイン済みのユーザーに対するオプション
//   内容: `TodoIndex`画面へ遷移する
const loggedInRedirectOption = {
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectLoggedInToIndex },
}

// ログインしていないユーザーに対するオプション
//   内容: `login`画面へ遷移する
const UnAuthorizedRedirectOption = {
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToLogin }
}

const routes: Routes = [
  // Todos
  { path: '', component: TodoIndexComponent, ...UnAuthorizedRedirectOption },
  // path: 'todos/new', 'todos/:todoId'の宣言順番に注意
  //   'todos/:todoId'を先に宣言すると、
  //   'todos/new'に遷移した時、`todoId`='new' としてDetailComponentに遷移する
  { path: 'todos/:todoId/edit', component: TodoEditComponent, ...UnAuthorizedRedirectOption },
  { path: 'todos/new', component: TodoNewComponent, ...UnAuthorizedRedirectOption },
  { path: 'todos/:todoId', component: TodoDetailsComponent, ...UnAuthorizedRedirectOption },

  // Tasks
  { path: 'todos/:todoId/tasks/:taskId/edit', component: TaskEditComponent, ...UnAuthorizedRedirectOption },
  { path: 'todos/:todoId/tasks/new', component: TaskNewComponent, ...UnAuthorizedRedirectOption },

  // User
  { path: 'profile', component: ProfileComponent, ...UnAuthorizedRedirectOption },
  { path: 'profile/edit', component: UserEditComponent, ...UnAuthorizedRedirectOption },

  // Auth
  { path: 'login', component: LoginComponent, ...loggedInRedirectOption },
  { path: 'signup', component: SignupComponent, ...loggedInRedirectOption }

  ////// Design
  // {
  //   path: '/design',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // }, {
  //   path: '/design',
  //   component: AdminLayoutComponent,
  //   children: [{
  //     path: '',
  //     loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  //   }]
  // }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      //  useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
