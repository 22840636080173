import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyApiService } from 'app/todos/my-api-service.service';
import { Task } from 'app/tasks/Task';
import { Todo } from 'app/todos/todos';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-task-new',
  templateUrl: './task-new.component.html',
  styleUrls: ['./task-new.component.scss']
})
export class TaskNewComponent implements OnInit {
  todoId: string
  errorMsg = '';

  constructor(
    private myApi: MyApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    // Set `todoId` property
    this.todoId = String(routeParams.get('todoId'));
  }

  createTask(task: Task): void {
    // serverへpost
    this.myApi.postTask(this.todoId, task)
      .then(() => {
        alert('Created new Task data! Title: ' + task.title)

        // move to TodoDetail
        this.router.navigate(['todos', this.todoId])
      },
        (error) => {
          // ErrorHandling
          this.errorMsg = error
        }
      )
  }

}
