import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, RequiredValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { MyApiService } from 'app/todos/my-api-service.service';
import { Todo } from 'app/todos/todos';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-todo-new',
  templateUrl: './todo-new.component.html',
  styleUrls: ['./todo-new.component.sass']
})
export class TodoNewComponent implements OnInit {
  newTodoForm = this.formBuilder.group({
    title: ['', Validators.required],
    importance: '',
    completed: false
  });
  errorMsg = '';
  user$ = this.auth.auth.user
  user: firebase.default.User

  constructor(
    private formBuilder: FormBuilder,
    private myApi: MyApiService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.user$.subscribe(user => {
      this.user = user
    })
  }

  createTodo(todo: Todo): void {
    // serverへpost
    this.myApi.postTodo(this.user.uid, todo)
      .then(() => {
        // move to Home
        this.router.navigate([''])
      },
        (error) => {
          // ErrorHandling
          // console.log(error)
          this.errorMsg = error
        }
      )

  }

}


