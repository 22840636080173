import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  constructor(
    private storage: AngularFireStorage,
    private auth: AngularFireAuth
  ) { }

  user: firebase.default.User | undefined
  errorMsg: string | undefined
  uploadPercent: Observable<number>;

  ngOnInit() {
    this.auth.user.subscribe(
      user => {
        this.user = user
        
      },
      error => this.errorMsg = error
    )
  }


  uploadFile(event) {
    if (!this.user) { return }

    const file = event.target.files[0];

    // if invalid
    if (!this.fileIsValid(file)) { return }

    // FilePath
    const filePath = `profileImage/${this.user.metadata.creationTime}`;
    const fileRef = this.storage.ref(filePath);
    const metadata = {
      contentType: 'image/*'
    }
    const task = fileRef.put(file, metadata)

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();

    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(
          downloadURL => {
            // `downloadURL`をAuthenticationに保存する
            const user = this.user

            user.updateProfile({
              photoURL: downloadURL
            })
              .then(() => {
                // Update完了後
                alert('Photo updated!')
                console.log('Update User PhotoURL. URL: ' + user.photoURL)
              })
              .catch(e => this.errorMsg = e)
          }
        )
      }),
    )
      .subscribe()
  }

  fileIsValid(file: File) {
    this.errorMsg = '' // clear old errorMsg

    let errorMsgs: string[] = [];

    // `type`が `image`のものかどうか
    const typeIsValid = file.type.startsWith('image/')
    if (!typeIsValid) {
      errorMsgs.push('画像ファイルのみアップロードできます。')
    }

    // `size`が 1MB以内かどうか
    const sizeIsValid = file.size <= 1000000
    if (!sizeIsValid) {
      errorMsgs.push('アップロードできるファイルサイズは1MBまでです。')
    }

    const fileIsValid = errorMsgs.length == 0
    this.errorMsg = errorMsgs.toString()

    return fileIsValid
  }
}
