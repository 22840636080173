import { Component, Input } from '@angular/core';
import { Todo } from 'app/todos/todos';

@Component({
  selector: 'app-todo-index-deleted-list',
  templateUrl: './todo-index-deleted-list.component.html',
  styleUrls: ['./todo-index-deleted-list.component.scss']
})
export class TodoIndexDeletedListComponent {

  constructor() { }

  @Input() todos: Todo[]
}
