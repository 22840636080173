import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Task } from 'app/tasks/Task';
import { MyApiService } from 'app/todos/my-api-service.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private myApi: MyApiService,
    private router: Router,
  ) { }

  errorMsg: string | undefined
  user$ = this.auth.auth.user
  user: firebase.default.User
  task: Task | undefined
  todoId: string
  taskId: string


  ngOnInit() {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.todoId = String(routeParams.get('todoId'));
    this.taskId = String(routeParams.get('taskId'));

    this.user$.subscribe(user => {
      this.user = user

      // Get Task from API
      this.myApi.getTask(this.user.uid, this.todoId, this.taskId)
        .subscribe(
          (task) => {
            this.task = task
          },
          (error) => this.errorMsg = error
        )
    })

  }

  // updateTask(data: Task) {
  //   // Send PUT request to API
  //   this.myApi.updateTask(this.todoId, data, this.taskId)
  //     .then((task) => {
  //       alert('Updated Task data! Title: ' + task.title)

  //       // move to Home
  //       this.router.navigate(['todos', this.todoId])
  //     },
  //       (error) => {
  //         // ErrorHandling
  //         // console.log(error)
  //         this.errorMsg = error
  //       }
  //     )
  // }
}