import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from 'app/tasks/Task';
import { MyApiService } from 'app/todos/my-api-service.service';
declare var $: any;

@Component({
  selector: 'app-todo-details-task-item',
  templateUrl: './todo-details-task-item.component.html',
  styleUrls: ['./todo-details-task-item.component.scss']
})
export class TodoDetailsTaskItemComponent implements OnInit {
  constructor(
    private myApi: MyApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  @Input() task!: Task;
  @Input() todoId: string;
  @Output() delete = new EventEmitter<Task>();
  errorMsg: string | undefined
  taskEditModalId: string;
  // @Output() toggleComplete = new EventEmitter<Task>();

  // public loadingToggleComplete = false

  // handleToggleComplete() {
  //   this.loadingToggleComplete = true
  //   this.toggleComplete.emit(this.task)
  //   this.loadingToggleComplete = false
  // }

  ngOnInit() {
    // get `todoId` from activatedRoute
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.todoId = String(routeParams.get('todoId'));

    this.taskEditModalId = `TaskEditModal${this.task.document_id}`;
  }

  updateTask(data: Task) {
    $(`#${this.taskEditModalId}`).modal('hide')
    this.myApi.updateTask(this.todoId, data, this.task.document_id)
    .then(() => {
        // // close Modal
      })
      .catch(error => this.errorMsg = error)
  }

}
