import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthApiService } from 'app/auth/auth-api.service';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  signupForm = this.formBuilder.group({
    'email': '',
    'password1': '',
    'password2': ''
  });
  errorMsg: string | undefined

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  onSubmit() {
    const data = this.signupForm.value

    // パスワードと確認用パスワードが一致していないとき、alert + Signup処理を中断
    if (data['password1'] != data['password2']) {
      alert('パスワードと確認用パスワードが一致していません')
      return
    }

    const toApiData = {
      email: data['email'],
      password1: data['password1'],
      password2: data['password2'],
    }

    this.authService.emailSignup(toApiData)
      .catch((error) => {
        console.log(error)
        this.errorMsg = error.message
      })
  }

  loginGoogle() {
    this.authService.googleLogin()
      .catch(error => this.errorMsg = error.message);
  }
}
