import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'app/tasks/Task';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-todo-index-task-list',
  templateUrl: './todo-index-task-list.component.html',
  styleUrls: ['./todo-index-task-list.component.scss']
})
export class TodoIndexTaskListComponent {
  @Input() tasks$!: Observable<Task[]>
}
