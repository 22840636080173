import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Todo } from 'app/todos/todos';

@Component({
  selector: 'app-todo-index-todo-list',
  templateUrl: './todo-index-todo-list.component.html',
  styleUrls: ['./todo-index-todo-list.component.sass']
})
export class TodoIndexTodoListComponent {
  @Input() todos: Todo[] | undefined;
  @Output() deleteTodo = new EventEmitter<Todo>();
  @Output() toggleTodoComplete = new EventEmitter<Todo>();

  // share() {
  //   window.alert('The product has been shared!');
  // }

  // onNotify() {
  //   window.alert('You will be notified when the product goes on sale');
  // }

  // 関数内から非同期アイテム使う時の書き方
  // showConfig() {
  //   this.configService.getConfig()
  //     .subscribe((data: Config) => this.config = {
  //       heroesUrl: data.heroesUrl,
  //       textfile: data.textfile,
  //       date: data.date,
  //     });
  // }

}
