import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Task } from 'app/tasks/Task';
import { MyApiService } from 'app/todos/my-api-service.service';
import { Todo, TodoState } from 'app/todos/todos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-todo-details',
  templateUrl: './todo-details.component.html',
  styleUrls: ['./todo-details.component.sass']
})
export class TodoDetailsComponent implements OnInit {
  errorMsg: string | undefined;
  user: firebase.default.User;
  user$ = this.auth.auth.user;
  todo$: Observable<Todo>;
  todo: Todo;
  tasks$: Observable<Task[]>;

  constructor(
    private auth: AuthService,
    private myApi: MyApiService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    // First get the `todoId` from the current route.
    const routeParams = this.route.snapshot.paramMap;
    const todoIdfromRoute = String(routeParams.get('todoId'));

    // Get Todo from API
    this.user$.subscribe(user => {
      if (!user) { return }

      this.user = user
      this.todo$ = this.myApi.getTodo(user.uid, todoIdfromRoute)
        .pipe(
          map(todo => {
            this.todo = todo

            // Init TodoEdit form
            this.initTodoEditForm(todo)

            return todo
          })
        )
      this.tasks$ = this.myApi.getTasks(user.uid, todoIdfromRoute)
    })
  }

  ////// TodoEditForm members //////
  estimatedHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  estimatedMinuteChoices = [
    0, 15, 30, 45
  ]
  todoForm: FormGroup;
  todoEditErrorMsg: string | undefined;

  initTodoEditForm(todo: Todo) {
    let estimatedHours = '0';
    let estimatedMinutes = '0';
    if (todo.duration) {
      const estimatedTimeData = todo.duration.split(':')
      estimatedHours = estimatedTimeData[0].replace('H', '').trim()
      estimatedMinutes = estimatedTimeData[1].replace('M', '').trim()
    }

    // Set values to FormInput
    this.todoForm = this.formBuilder.group({
      title: todo.title,
      importance: todo.importance,
      completed: todo.completed,
      estimatedTimeHours: estimatedHours,
      estimatedTimeMinutes: estimatedMinutes,
      startTime: todo.start_time
    })
  }

  onSubmit() {
    const formData = this.todoForm.value

    //// APIへ送るためのデータを作る ////
    // estimatedTime:  例 '3 H : 5 M'
    const toApiEstimatedTime = `${formData['estimatedTimeHours']} H : ${formData['estimatedTimeMinutes']} M`

    const toApiData = {
      title: formData['title'],
      importance: formData['importance'],
      completed: formData['completed'], // Update時は、`completed`もフォームから取る
      start_time: formData['startTime'],
      duration: toApiEstimatedTime
    }

    this.updateTodo(toApiData)
  }
  ///// end TodoEditForm members /////



  ////// Todo, Task CRUD ///////
  updateTodo(data: Todo) {
    // Send PUT request to API
    this.myApi.updateTodo(data, this.todo.document_id)
      .then(_ => {
        console.log('Finish update Todo!')
        $('#todoEditModal').modal('hide');
      })
      .catch(error => {
        // ErrorHandling
        // console.log(error)
        this.todoEditErrorMsg = error
      })
  }

  deleteTask(task: Task) {
    const todoId = this.todo.document_id
    const taskId = task.document_id
    const title = task.title

    this.myApi.deleteTask(todoId, taskId)
      .then(
        (_) => {
          alert(`Deleted Task! Title is ${title}`)
          // this.getTasks(todoId)
        },
        (error) => this.errorMsg = error
      )
  }

  toggleTaskComplete(task: Task) {
    /*
      `task.completed`がTrueかFalseかによる処理分けをする
    */
    const completed = task.completed
    if (completed == true) {
      this.incompleteTask(task)
    } else {
      this.completeTask(task)
    }
  }

  completeTask(task: Task) {
    /*
    `task_complete`APIへPUTリクエストを送る
    リクエストが成功した場合 `task.completed`をTrueにする
    */
    this.myApi.completeTask(this.todo.document_id, task.document_id).subscribe(
      (_) => {
        task.completed = true
        console.log('Completed Task!')
      },
      error => this.errorMsg = error
    )
  }
  incompleteTask(task: Task) {
    /* 
    `task_complete`APIへDELETEリクエストを送る
    リクエストが成功した場合 `task.completed`をFalseにする
    */
    this.myApi.incompleteTask(this.todo.document_id, task.document_id).subscribe(
      (_) => {
        task.completed = false
        console.log('Incompleted Task!')
      },
      error => this.errorMsg = error
    )
  }
  ////// end Todo,Task CRUD ///////


}
