import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { MyApiService } from 'app/todos/my-api-service.service';
import { Todo } from 'app/todos/todos';

@Component({
  selector: 'app-todo-edit',
  templateUrl: './todo-edit.component.html',
  styleUrls: ['./todo-edit.component.sass']
})
export class TodoEditComponent implements OnInit {
  errorMsg: string | undefined
  user$ = this.auth.auth.user
  user: firebase.default.User
  todo: Todo | undefined

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private myApi: MyApiService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    const todoIdFromRoute = String(routeParams.get('todoId'));

    // Get Todo from API
    this.user$.subscribe(user => {
      this.myApi.getTodo(user.uid, todoIdFromRoute)
        .subscribe(
          (todo) => {
            this.todo = todo
          },
          (error) => this.errorMsg = error
        )
    })
  }

  updateTodo(data: Todo) {
    // Send PUT request to API
    this.myApi.updateTodo(data, this.todo.document_id)
      .then(_ => {
        alert('Updated Todo data! Title: ' + data.title)

        // move to Home
        this.router.navigate([''])
      })
      .catch(error => {
        // ErrorHandling
        // console.log(error)
        this.errorMsg = error
      })
  }
}

