import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Task } from '../tasks/Task';
import { Todo, TodoState } from './todos';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class MyApiService {
  constructor(
    private http: HttpClient,
    private db: AngularFirestore
  ) { }

  baseUrl = 'http://localhost:8000/api/'

  defaultRequestHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true
    }
  }
  // Todo
  todoIndexUrl() {
    return `${this.baseUrl}todos/`
  }
  todoShowUrl(todoId: string) {
    return `${this.baseUrl}todos/${todoId}/`
  }
  todoCompleteUrl(todoId: string) {
    return `${this.todoShowUrl(todoId)}complete/`
  }
  todoRestoreUrl(todoId: string) {
    return `${this.todoShowUrl(todoId)}restore/`
  }

  // Task
  taskIndexUrl(todoId: string) {
    return `${this.todoShowUrl(todoId)}tasks/`
  }
  taskShowUrl(todoId: string, taskId: string) {
    return `${this.todoShowUrl(todoId)}tasks/${taskId}/`
  }
  taskCompleteUrl(todoId: string, taskId: string) {
    return `${this.taskShowUrl(todoId, taskId)}complete/`
  }
  taskRestoreUrl(todoId: string, taskId: string) {
    return `${this.taskShowUrl(todoId, taskId)}restore/`
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp()
  }


  ////// Todos Endpoints //////

  getInProgressTodos(userId: string) {
    /*
      state: InProgressの仕様

      state: 'delete'がfalse
      'updated_at'が48時間以内のもの
    */
    var now = new Date();
    var twoDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);

    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => {
        return ref
          .where('user_id', '==', userId)
          .where('updated_at', '>=', twoDaysAgo)
      }
    )

    return userTodosRef.valueChanges().pipe(
      /**
       * Firestoreのクエリ制限による自前クエリ
       * - state != 'delete'
       */
      map(todos => todos.filter(todo => todo.state != TodoState.Delete)),
      
      // 子のTasksを取得
      tap(todos => todos.map(todo => {
        todo.tasks$ = this.getTasks(userId, todo.document_id)
      }))
    )
  }

  getArchiveTodos(userId: string) {
    /*
      state: Archiveの仕様

      state: 'completed'になってから、48H経過しているものはひょうじしない
      state: 'completed' かつ 'updated_at'が48時間より前
    */
    var now = new Date();
    var twoDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);

    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => {
        return ref
          .where('user_id', '==', userId)
          .where('completed', '==', true)
          .where('updated_at', '<=', twoDaysAgo)
      }
    )

    return userTodosRef.valueChanges().pipe(
      /**
       * Firestoreのクエリ制限による自前クエリ
       * - state != 'delete'
       */
      map(todos => todos.filter(todo => todo.state != TodoState.Delete)),

      // 子のTasksを取得
      tap(todos => todos.map(todo => {
        todo.tasks$ = this.getTasks(userId, todo.document_id)
      }))
    )
  }

  getDeletedTodos(userId: string) {
    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => {
        return ref
          .where('user_id', '==', userId)
          .where('state', '==', TodoState.Delete)
      }
    )

    // 子のTasksを取得
    return userTodosRef.valueChanges().pipe(
      tap(todos => todos.map(todo => {
        todo.tasks$ = this.getTasks(userId, todo.document_id)
      }))
    )
  }

  async postTodo(userId: string, todo: Todo) {
    const todosRef = this.db.collection<Todo>('todos');
    const todoRef = await todosRef.add({} as Todo)

    // Set metadata to save
    todo.document_id = todoRef.id
    todo.user_id = userId
    todo.created_at = this.timestamp as any
    todo.updated_at = this.timestamp as any

    return todoRef.set(todo)
      .then(() => {
        console.log('Created Todo! title is ' + todo.title)
      })
  }

  getTodo(userId: string, todoId: string) {
    // 与えられた`user`のもつ`Todos`の中から、`todo`を取得
    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => ref.where('user_id', '==', userId)
    )
    const todoDoc = userTodosRef
      .doc<Todo>(`/${todoId}`);

    return todoDoc.valueChanges()
      .pipe(
        map(todo => {
          // Timestamp型をDate型に変換
          const timestamp: any = todo.start_time as any // start_timeを型変換
          const _timestamp: firebase.firestore.Timestamp = timestamp
          todo.start_time = _timestamp?.toDate()
          return todo
        })
      )
  }

  async updateTodo(todo: Todo, todoId: string) {
    const todoRef = this.db.doc<Todo>(`todos/${todoId}`);
    
    // Set metadata
    todo.updated_at = this.timestamp as any

    return todoRef.update(todo)
      .then(() => console.log('Update Todo! title is ' + todo.title))
  }

  async todoHasIncompleteTasks(todoDoc: AngularFirestoreDocument<Todo>) {
    /*
      Task
      ----
      Todoが未完了なタスクを持っているかの真偽を返す

      Return
      ------
      True => 未完了タスクを持っている
      False => 未完了タスクなし！削除してOK！
    */
    
    // まだ未完了なタスクを取得
    const tasks = await todoDoc.collection(
      'tasks', ref => {
        return ref.where("completed", '!=', true)
        // return ref.where('state', '==', 'inProgress' as TodoState)
      }
    ).get().toPromise()

    return tasks.docs.length != 0
  }

  async deleteTodo(todoId: string) {
    /*
      Task
      ----
      `state`: deleteにする

      全ての子タスクが`completed`状態になっていないと、削除できない


      Raise
      -----
      Error: 未完了なタスク(子モデル)があるとき
    */

    const todoDoc = this.db.doc<Todo>(`todos/${todoId}`);
    const todoHasIncompleteTasks = await this.todoHasIncompleteTasks(todoDoc)
    const todo = (await todoDoc.get().toPromise()).data()

    /**
     * Validation
     * - 未完了なタスクがないか
     * - Todo.completed == Trueになっているか
     */
    if (!todo.completed) {
      throw Error('Todo is not completed')
    }
    if (todoHasIncompleteTasks) {
      // 未完了なタスクがあれば、throw Error
      throw Error('All Tasks not completed')
    }

    return todoDoc.update({ state: TodoState.Delete })
      .then(() => console.log('Deleted Todo!'))
  }

  ////// Tasks Endpoints //////
  getTasks(userId: string, todoId: string = '') {
    // 与えられた`user`のもつ`Todos`の中から、`todo`を取得
    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => ref.where('user_id', '==', userId)
    )
    const todoDoc = userTodosRef
      .doc<Todo>(`/${todoId}`);

    const taskDocs = todoDoc.collection<Task>('tasks').valueChanges()

    return taskDocs
  }

  async postTask(todoId: string, task: Task) {
    const tasksRef = this.db.collection<Task>(`todos/${todoId}/tasks`);
    const taskRef = await tasksRef.add({} as Task)

    // Set metadata to save
    task.document_id = taskRef.id
    task.created_at = this.timestamp as any
    task.updated_at = this.timestamp as any

    return taskRef.set(task)
      .then(() => console.log('Created Task! title is ' + task.title))
  }

  getTask(userId: string, todoId: string, taskId: string) {
    // 与えられた`user`のもつ`Todos`の中から、`task`を取得
    const userTodosRef = this.db.collection<Todo>(
      'todos', ref => ref.where('user_id', '==', userId)
    )
    const taskDoc = userTodosRef
      .doc<Task>(`/${todoId}/tasks/${taskId}`);

    return taskDoc.valueChanges()
  }

  async updateTask(todoId: string, task: Task, taskId: string) {
    const taskDoc = this.db.doc<Task>(`todos/${todoId}/tasks/${taskId}`)

    // Set metadata to save
    task.updated_at = this.timestamp as any

    return taskDoc.update(task)
      .then(() => {
        console.log('Updated Task! title is ' + task.title)
      })
  }

  async deleteTask(todoId: string, taskId: string) {
    /*
      `state`: deleteにする
    */
    const taskDoc = this.db.doc<Task>(`todos/${todoId}/tasks/${taskId}`);

    return taskDoc.update({ state: TodoState.Delete })
      .then(() => console.log('Deleted Task!'))
  }

  /////// Complete Action Endpoint ///////

  async completeTodo(todoId: string) {
    /*
      Task
      ----
      completed => Trueに
      state     => 'completed'に変更


      Raise
      -----
      Error: まだ未完了なタスク(子モデル)があるとき
    */

    const todoDoc = this.db.doc<Todo>(`todos/${todoId}`);
    const todoHasIncompleteTasks = await this.todoHasIncompleteTasks(todoDoc)

    if (todoHasIncompleteTasks) {
      throw Error('All Tasks not completed')
    }

    return this.updateTodo({ completed: true } as Todo, todoId)
  }

  incompleteTodo(todoId: string) {
    /*
      completed => falseに
      state     => 'inProgress'に変更
    */
    return this.updateTodo({ completed: false } as Todo, todoId)
  }

  // CompleteTask
  completeTask(todoId: string, taskId: string) {
    console.log('before send request to Complete Task! ID is ' + taskId)

    const url = this.taskCompleteUrl(todoId, taskId)
    const header = this.defaultRequestHeader()

    // PUTリクエストを送る
    return this.http.put<Task>(url, null, header)
      .pipe(
        catchError(this.handleError)
      );
  }

  incompleteTask(todoId = '', taskId: string) {
    console.log('before send request to Incomplete Task! ID is ' + taskId)

    const url = this.taskCompleteUrl(todoId, taskId)
    const header = this.defaultRequestHeader()

    // DELETEリクエストを送る
    return this.http.delete<Task>(url, header)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // 4XX, 5XX エラー
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

}
