import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Unsubscribe } from '@firebase/util';
import firebase from 'firebase/compat/app';
import { UserLogin, UserSignup } from './User';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User, UserDocument } from 'app/users/User';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  constructor(
    public auth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router
  ) { }

  user$ = this.auth.user.pipe(
    map(user => {
      /*
        ComponentからUserDocumentにアクセスするためには、
        `user.document.subscribe()のように、
        Userとは別に、UserDocumentもサブスクライブする
      */ 
      const _user = user as User
      _user.document = this.getUserDocument$(user.email)

      return _user
    })
  )

  ngOnInit() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in or token was refreshed.
        alert('Token Refreshed!')
      } else {
        alert('Token Expired!')
        this.router.navigateByUrl('/login')
      }
    });
  }

  getUserDocument$(email: string) {
    const userDocs = this.db.collection<UserDocument>(
      'users',
      ref => ref.where('email', '==', email)
    ).valueChanges()

    return userDocs.pipe(
      map(users => {
        return users[0]
      })
    )
  }

  async login(data: UserLogin, redirectUrl = '') {
    return this.auth.signInWithEmailAndPassword(data.email, data.password)
      .then(value => {
        console.log('Success', value);

        // Djangoでログイン状態にするために、cookieにtoken貼る
        value.user?.getIdToken().then(
          (idToken) => {
            document.cookie = `token=${idToken}`;

            // EmailLogin成功後、`TodoIndex`画面に遷移する
            this.router.navigateByUrl(redirectUrl);
          }
        )

      })
  }

  async emailSignup(data: UserSignup, redirectUrl = '') {
    const signupInfo = await this.auth.createUserWithEmailAndPassword(data.email, data.password1);
    const saveData: UserDocument = {
      email: signupInfo.user?.email || ''
    };
    await this.db.collection<UserDocument>('users').add(saveData);

    this.router.navigateByUrl(redirectUrl);
    // FirestoreにUserDocumentを作成
  }

  async googleLogin(redirectUrl = '') {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(value => {
        // 新しいUserの場合、Firestoreへ UserDataを保存
        if (value.additionalUserInfo?.isNewUser) {
          const saveData: UserDocument = {
            email: value.user?.email || ''
          };
          return this.db.collection<UserDocument>('users').add(saveData);
        }
      })
      .then(_ => {
        this.router.navigateByUrl(redirectUrl);
      })
  }

  async logout(redirectUrl = 'login') {
    return this.auth.signOut()
      .then(() => {
        // cookieに貼っていたtokenを消す
        alert('Logged Out!');
        this.router.navigateByUrl(redirectUrl);
      })
  }


}
