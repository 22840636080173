import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Todo } from 'app/todos/todos';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-todo-index-todo-item',
  templateUrl: './todo-index-todo-item.component.html',
  styleUrls: ['./todo-index-todo-item.component.sass']
})
export class TodoIndexTodoItemComponent {
  @Input() todo!: Todo;
  @Output() delete = new EventEmitter<Todo>()
  @Output() toggleComplete = new EventEmitter<Todo>()

  handleClickCheckbox(e: Event) {
    e.preventDefault()
    this.toggleComplete.emit(this.todo)
  }
}
