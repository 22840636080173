import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Task } from 'app/tasks/Task';
import { EventEmitter } from '@angular/core';
import { TodoState } from 'app/todos/todos';

@Component({
  selector: 'app-task-edit-task-form',
  templateUrl: './task-edit-task-form.component.html',
  styleUrls: ['./task-edit-task-form.component.scss']
})
export class TaskEditTaskFormComponent implements OnInit {
  @Input() task!: Task
  @Output() save = new EventEmitter<Task>();
  errorMsg = '';

  // Task Form
  estimatedHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  estimatedMinuteChoices = [
    0, 15, 30, 45
  ]
  actualHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  actualMinuteChoices = [
    0, 15, 30, 45
  ]
  // Update時は、`completed, actualTime`入れる
  taskForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    // Get `estimatedHours`
    let estimatedHours = '0';
    let estimatedMinutes = '0';
    if (this.task.estimatedHours) {
      const _estimatedTime = this.task.estimatedHours.split(':')
      estimatedHours = _estimatedTime[0].replace('H', '').trim()
      estimatedMinutes = _estimatedTime[1].replace('M', '').trim()
    }

    // Get `actualHours`
    let actualHours = '0';
    let actualMinutes = '0';
    if (this.task.actualHours) {
      const actualTime = this.task.actualHours.split(':')
      actualHours = actualTime[0].replace('H', '').trim()
      actualMinutes = actualTime[1].replace('M', '').trim()
    }

    // Set values to FormInput if `task` is present.
    this.taskForm = this.formBuilder.group({
      title: this.task.title,
      workFlow: this.task.workFlow,
      completed: this.task.completed,
      estimatedHours: estimatedHours,
      estimatedMinutes: estimatedMinutes,
      actualHours: actualHours,
      actualMinutes: actualMinutes
    })
  }

  onSubmit() {
    const formData: Object = this.taskForm.value;

    // APIへ送るためのデータを作る
    const toApiActualTime = `${formData['actualHours']} H : ${formData['actualMinutes']} M`
    const toApiEstimatedTime = `${formData['estimatedHours']} H : ${formData['estimatedMinutes']} M`
    const state: TodoState = formData['completed'] ? 'completed' : 'inProgress'

    const toApiData = {
      title: formData['title'],
      workFlow: formData['workFlow'],
      completed: formData['completed'],
      actualHours: toApiActualTime,
      estimatedHours: toApiEstimatedTime,
      state
    }

    this.save.emit(toApiData);
  }
}
