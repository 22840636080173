import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// TodoAppComponents
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { TodoIndexTodoListComponent } from './todos/components/todo-index/todo-index-todo-list/todo-index-todo-list.component';
import { TodoDetailsComponent } from './todos/components/todo-details/todo-details.component';
import { TodoNewComponent } from './todos/components/todo-new/todo-new.component';
import { TodoEditComponent } from './todos/components/todo-edit/todo-edit.component';
import { TodoIndexComponent } from './todos/components/todo-index/todo-index.component';
import { TodoIndexTodoItemComponent } from './todos/components/todo-index/todo-index-todo-item/todo-index-todo-item.component';

// DateTimePicker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

// Firebase

// DesignComponents
// import { ComponentsModule } from './components/components.module';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { UserProfileComponent } from './user-profile/user-profile.component';
// import { TableListComponent } from './table-list/table-list.component';
// import { TypographyComponent } from './typography/typography.component';
// import { IconsComponent } from './icons/icons.component';
// import { MapsComponent } from './maps/maps.component';
// import { NotificationsComponent } from './notifications/notifications.component';
// import { UpgradeComponent } from './upgrade/upgrade.component';
// import {
//   AgmCoreModule
// } from '@agm/core';
// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TaskEditComponent } from './tasks/components/task-edit/task-edit.component';
import { TaskNewComponent } from './tasks/components/task-new/task-new.component';
import { TodoIndexTaskListComponent } from './todos/components/todo-index/todo-index-task-list/todo-index-task-list.component';
import { TodoIndexTaskItemComponent } from './todos/components/todo-index/todo-index-task-item/todo-index-task-item.component';
import { TodoDetailsTaskListComponent } from './todos/components/todo-details/todo-details-task-list/todo-details-task-list.component';
import { TodoDetailsTaskItemComponent } from './todos/components/todo-details/todo-details-task-item/todo-details-task-item.component';
import { TodoEditTodoFormComponent } from './todos/components/todo-edit/todo-edit-todo-form/todo-edit-todo-form.component';
import { TodoNewTodoFormComponent } from './todos/components/todo-new/todo-new-todo-form/todo-new-todo-form.component';
import { TaskEditTaskFormComponent } from './tasks/components/task-edit/task-edit-task-form/task-edit-task-form.component';
import { TaskNewTaskFormComponent } from './tasks/components/task-new/task-new-task-form/task-new-task-form.component';
import { ProfileComponent } from './user-profile/user-profile.component';
import { LoginComponent } from './auth/components/login/login.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';

// AngularFire Modules
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { UserEditComponent } from './users/components/user-edit/user-edit.component';
import { TodoDetailsTaskFormComponent } from './todos/components/todo-details-task-form/todo-details-task-form.component';
import { TodoIndexDeletedListComponent } from './todos/components/todo-index/todo-index-deleted-list/todo-index-deleted-list.component';

const firebaseConfig = {
  'apiKey': "AIzaSyCJOlPerUKxZg9F9WEhv8mCnv1KNYy5LEY",
  'authDomain': "ios-todo-swift.firebaseapp.com",
  'databaseURL': "https://ios-todo-swift.firebaseio.com",
  'projectId': "ios-todo-swift",
  'storageBucket': "ios-todo-swift.appspot.com",
  'messagingSenderId': "852941523503",
  'appId': "1:852941523503:web:4175746072aa741fe354da",
  'measurementId': "G-T1D1GW2WWN"
}

registerLocaleData(localeJa);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,

    // Material
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    // }),
    // ComponentsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,

    // DateTimePicker
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule
  ],
  declarations: [
    ////// Todo //////
    AppComponent,

    // Index
    TodoIndexComponent,
    TodoIndexTodoListComponent,
    TodoIndexTodoItemComponent,
    TodoIndexTaskListComponent,
    TodoIndexTaskItemComponent,
    TodoIndexDeletedListComponent,

    // Detail
    TodoDetailsComponent,
    TodoDetailsTaskListComponent,
    TodoDetailsTaskItemComponent,
    TodoDetailsTaskFormComponent,

    // New
    TodoNewComponent,
    TodoNewTodoFormComponent,

    // Edit
    TodoEditComponent,
    TodoEditTodoFormComponent,

    ////// Task //////
    TaskNewComponent,
    TaskNewTaskFormComponent,
    TaskEditComponent,
    TaskEditTaskFormComponent,

    ////// Auth //////
    LoginComponent,
    SignupComponent,

    ///// Layout /////
    NavbarComponent,
    SidebarComponent,
    FooterComponent,

    ///// User Profile /////
    ProfileComponent,
    UserEditComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ja-JP' },
    { provide: LOCALE_ID, useValue: 'ja-JP' },

    // Firebase Settings
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
