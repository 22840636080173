import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Todo, TodoState } from 'app/todos/todos';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-todo-edit-todo-form',
  templateUrl: './todo-edit-todo-form.component.html',
  styleUrls: ['./todo-edit-todo-form.component.scss']
})
export class TodoEditTodoFormComponent implements OnInit {
  @Input() todo!: Todo
  @Input() errorMsg: string | undefined;
  @Output() save = new EventEmitter<Todo>()

  // Todo Form
  estimatedHourChoices = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
  ]
  estimatedMinuteChoices = [
    0, 15, 30, 45
  ]
  todoForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    let estimatedHours = '0';
    let estimatedMinutes = '0';
    if (this.todo.duration) {
      const estimatedTimeData = this.todo.duration.split(':')
      estimatedHours = estimatedTimeData[0].replace('H', '').trim()
      estimatedMinutes = estimatedTimeData[1].replace('M', '').trim()
    }

    // Set values to FormInput
    this.todoForm = this.formBuilder.group({
      title: this.todo.title,
      importance: this.todo.importance,
      completed: this.todo.completed,
      estimatedTimeHours: estimatedHours,
      estimatedTimeMinutes: estimatedMinutes,
      startTime: this.todo.start_time
    })
  }

  onSubmit() {
    const formData = this.todoForm.value

    //// APIへ送るためのデータを作る ////
    // estimatedTime:  例 '3 H : 5 M'
    const toApiEstimatedTime = `${formData['estimatedTimeHours']} H : ${formData['estimatedTimeMinutes']} M`

    const toApiData = {
      title: formData['title'],
      importance: formData['importance'],
      completed: formData['completed'], // Update時は、`completed`もフォームから取る
      start_time: formData['startTime'],
      duration: toApiEstimatedTime
    }

    this.save.emit(toApiData)
  }
}